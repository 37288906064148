import React, {Suspense} from "react";
import { Route, Switch } from "react-router-dom";
import Spinner from './containers/Spinner.js';
import Xdent from './containers/Xdent.js';

//import Visualizer from './containers/BCVisualizer.js';
//import VCVisualizer from './containers/VCVisualizer.js';
//import LogoVisualizer from './containers/LogoVisualizer.js';

const AuthenticatedRoute = React.lazy(() => import('./components/AuthenticatedRoute.js'));
const UnauthenticatedRoute = React.lazy(() => import('./components/UnauthenticatedRoute.js'));
const Login = React.lazy(() => import('./containers/Login.js'));
const Signup = React.lazy(() => import('./containers/Signup.js'));
const Reset = React.lazy(() => import('./containers/Reset.js'));

export default function Routes() {
  return (
        <Suspense fallback={<Spinner height="100vh"/>}>

    <Switch>
<UnauthenticatedRoute exact path="/login">
  <Login />
</UnauthenticatedRoute>
<UnauthenticatedRoute exact path="/signup">
  <Signup />
</UnauthenticatedRoute>
<UnauthenticatedRoute exact path="/reset">
  <Reset />
</UnauthenticatedRoute>

<AuthenticatedRoute exact path="/">
  <Xdent />
</AuthenticatedRoute>
{/* Finally, catch all unmatched routes */}
    </Switch>
</Suspense>
  );
}
