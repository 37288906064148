import React, { useState, useRef, useEffect} from "react";
import API from '@aws-amplify/api';
import Spinner from './Spinner.js';
import '../App.css';
import search from './script.js';

export default function Lectios(props) {

 
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");


  useEffect( async () => {

  }, []);



  return(


<div class="container-fluid bg-dark ">
  <div class="d-flex flex-wrap pt-2">
    <div class="col-12">
        <div>
          <h1 style={{color:"gray", fontFamily: "Inconsolata"}}>welcome to lectios</h1>
          <p style={{color:"gray",fontFamily:"Inconsolata"}}>project by krsna sirohi | batch of '25 | NAFL</p>
        </div>
  
        <div class="col-sm-12 col-md-12">
          <div class="my-5 col-lg-4 offset-lg-4 col-sm-12 col-md-6 offset-md-3">
            <div class="d-flex align-items-center justify-content-center">
            <input class="search-input w-75" style={{fontFamily: "Inconsolata", backgroundColor: "#e8e4dc", padding: "10px"}} type="text" 
            placeholder="search by title" id="search" onKeyDown={(e) => search(e)}/>
            <button onClick={(e)=>search(e)} class="search position-relative" style={{color: "#e8e4dc", backgroundColor: "black"}}></button> 
            </div>
          </div>
  
          <div id="results" class="bg-dark col-md-10 offset-md-1 col-sm-12"></div> 
          <div class="col-md-10 offset-md-1 col-sm-12"> 
            <p id="loading" class="mt-3" style={{color:"gray", fontFamily: "Inconsolata", display: "none"}}>getting results...</p>
          </div>  
        </div>

    </div>

    <div class="position-absolute top-1 end-0 pt-2">
      <div class="d-flex flex-column">
        <p style={{color:"gray", fontFamily: "Inconsolata"}} class="pe-2 topcorner1 d-md-block d-none mx-0">shout out dhruva rau and samarth rt</p>
        <p style={{color:"gray", fontFamily: "Inconsolata"}} class="topcorner3 d-md-block d-none mx-0">logged in as NAFL</p>
      </div>
    </div>
  </div>


</div>

  );
}


