import $ from 'jquery';


export default async function search(event) {
  //console.log(event);
  if (event.key === "Enter" || event.type==="click") {

// Update this variable to point to your domain.
var apigatewayendpoint = 'https://3yocgqmaia.execute-api.ap-south-1.amazonaws.com/test/';
var loadingdiv = $('#loading');
var noresults = $('#noresults');
var resultdiv = $('#results');
var searchbox = $('input#search');
//var timer = 0;

  // Clear results before searching
  noresults.hide();
  resultdiv.empty();
  loadingdiv.show();
  // Get the query from the user
  //console.log(searchbox.val());
  let query = searchbox.val().toLowerCase();
  // Only run a query if the string contains at least three characters
  if (query.length > 2) {
    // Make the HTTP request with the query as a parameter and wait for the JSON results
    let response = await $.get(apigatewayendpoint, {  school: "NAFL", expression: query, size: 5 }, 'json');
    // Get the part of the JSON response that we care about
    //let results = response['hits']['hits'];
    let results = response;
    //console.log(results);
    if (results.length > 5) {
      results = results.slice(0,5);
    } //SEE THIS 
    if (results.length > 0) {
      loadingdiv.hide();
      // Iterate through the results and write them to HTML
      resultdiv.append('<p class="mt-3" style="color:gray; font-family:Inconsolata">found ' + results.length + ' results.</p>');
      for (var item in results) {
        //console.log(item);
        let fetchRes = await fetch( "https://www.googleapis.com/books/v1/volumes?q=intitle:"+results[item].title);
	      let res = await fetchRes.json();
        let accnumber = results[item].accno;
        let url="#";
        let image = 'no-image.png';
        let title=results[item].title;
        let year='Unavailable';
        let description='Unavailable';
        let author='Unavailable';
        let rating='Unavailable';

	      if (res.items && res.items.length > 0) {
          var item = res.items[0];

          url = item.volumeInfo.canonicalVolumeLink;
          if (item.volumeInfo.imageLinks && item.volumeInfo.imageLinks.thumbnail) {
		        image = item.volumeInfo.imageLinks.thumbnail;
	        }
          title = item.volumeInfo.title;
          description = item.volumeInfo.description;
          author = item.volumeInfo.authors;
          //let accnumber = results[item]._source.year;
          if (item.volumeInfo.publishedDate) {
		        year = item.volumeInfo.publishedDate.substring(0,4);
	        }

          rating = item.volumeInfo.averageRating || 'Unavailable';
        }
        //let accnumber = results[item]._source.accession;
        // Construct the full HTML string that we want to append to the div


        resultdiv.append('<div class="result1 card m-1 p-3 bg-dark" id="1resultcard" style="border: 2px solid gray;"><div class="card-body row" id="resultcard">' +
        '<div class="col-md-3 col-sm-12 p-1" id="1resultcard"><img class="img-fluid img-thumbnail w-75" src="' + image + '" ></div>' +
        '<div class="col-md-9 col-sm-12 p-1" id="1resultcard"><h2 style="color:#e8e4dc; font-family:Inconsolata"><a target = "_blank" href="' + url + '" style="color:beige">' + title + '</a></h2><p style="color:#E8E4DC; font-family:Inconsolata">Author: ' + author + 
        ' </p><p style="color:#e8e4dc; font-family:Inconsolata">Description: ' + description + ' </p><p style="color:#e8e4dc; font-family:Inconsolata">Published year: ' + year + ' </p><p style="color:#e8e4dc; font-family:Inconsolata">Rating: ' + rating + ' </p><p style="color:#e8e4dc; font-family:Inconsolata">Accesion number: ' + accnumber + ' </p></div></div></div>');
	
	//ending callback after fetch
//	});


      }
    } else {
      //noresults.show();
      resultdiv.append('<p id="noresults" class="mt-3" style="color:gray; font-family:Inconsolata">No results. Try another search term.</p>');
    }
  }
  loadingdiv.hide();

  // end if key=enter
  }

}

// Tiny function to catch images that fail to load and replace them
function imageError(image) {
  image.src = 'images/no-image.png';
}

